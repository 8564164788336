<template>
  <div>
    <div v-if="validationScreen == 0">
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-top: 3em;">
            <country-phone-entry
              :formatting="false"
              hintText="Campo obligatorio *"
              :phone="phone"
              :codePhone="countrySelectedCode"
              @setPhone="value => (phone = value)"
              @setCodePhone="value => (countrySelectedCode = value)"
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="space-around">
          <v-btn
            fab
            @click="nextBtnSearch()"
            :loading="loading.next"
            :disabled="!disabledNext"
            color="white"
            small
          >
            <v-icon dark>
              mdi-arrow-right-bold
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <div v-if="validationScreen == 1">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <!--Campo de nombre -->
            <v-col cols="12">
              <h3
                color="primary"
                align="center"
                justify="space-around"
                v-if="search.phone == 1"
              >
                Se encontró un broker externo registrado..
              </h3>
              <v-row>
                <v-col cols="12" style="padding-top: 2em;">
                  <v-text-field
                    :disabled="disabled.form"
                    dense
                    autocomplete="off"
                    id="txtLeadName"
                    label="Nombre *"
                    hint="Campo obligatorio *"
                    persistent-hint
                    :counter="35"
                    v-model="exBr.name"
                  />
                </v-col>
              </v-row>
            </v-col>

            <!--Campo de E-Mail -->

            <v-col cols="12">
              <v-text-field
                ref="entry"
                :disabled="disabled.form"
                :rules="emailRules"
                dense
                label="E-mail (Correo electrónico)"
                persistent-hint
                v-model="exBr.email"
              >
                <template v-slot:append>
                  <v-progress-circular
                    v-if="loading.email"
                    size="24"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <div v-else>
                    <v-icon
                      style="vertical-align: middle"
                      color="#F1C40F"
                      v-if="search.email == 1"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                    <v-icon
                      style="vertical-align: middle"
                      color="green"
                      v-if="search.email == 2"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </div>
                </template>
              </v-text-field>
            </v-col>

            <!--Campo de Telefono -->
            <v-col cols="12">
              <country-phone-entry
                :disabled="disabled.phone"
                :phone="exBr.phone"
                :codePhone="exBr.countrySelectedCode"
                hintText="Campo obligatorio *"
                @setPhone="value => (exBr.phone = value)"
                @setCodePhone="value => (exBr.countrySelectedCode = value)"
              />
            </v-col>

            <!--Campo de Inmobiliaria -->
            <v-col cols="12">
              <v-text-field
                :disabled="disabled.form"
                dense
                label="Inmobiliaria"
                hint="Campo opcional"
                persistent-hint
                v-model="exBr.real_estate_name"
              />
            </v-col>
          </v-row>
          <v-row
            align="center"
            justify="space-around"
            v-if="typeComponent != 'ExternalBroker'"
          >
            <v-btn @click="back()" color="white" fab small>
              <v-icon>
                mdi-arrow-left-thick
              </v-icon>
            </v-btn>
          </v-row>
          <v-row
            align="center"
            justify="start"
            v-if="typeComponent == 'ExternalBroker'"
          >
            <v-btn @click="back()" color="white" fab small>
              <v-icon>
                mdi-arrow-left-thick
              </v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import { searchByRepeated } from "@/api/external-broker-api.js";
export default {
  name: "ExternalBrokerSearchAndRegistration",
  components: {
    CountryPhoneEntry: () =>
      import("@/components/lead/components/CountryPhoneEntry.vue")
  },
  props: {
    /* Vamos a indicar donde se va a usar el componente...
    Se puede utilizar para crear un nuevo broker externo y hay que validarlo...
    Tambien se puede utilizar para validar un lead con broker externo.. */
    typeComponent: {
      String: String,
      default: "ExternalBroker"
    }
  },
  data() {
    return {
      //Validation
      validationScreen: 0,
      emailRules: [
        v => /.+@.+/.test(v) || v.length == 0 || "E-mail debe ser valido"
      ],
      valid: true,
      validSearchEmail: false,
      exBr: {
        name: "",
        email: "",
        real_estate_name: "",
        //Datos de telefono..
        phone: "",
        countrySelectedCode: "+52"
      },
      phone: "",
      countrySelectedCode: "+52",
      tempPhone: "",
      //Loading
      loading: {
        next: false,
        email: false
      },
      disabled: {
        next: false,
        form: false,
        phone: true
      },
      //search.phone: 0,
      search: {
        phone: 0,
        /* 
        0: No mostrar icono.
        1: Alerta No Puede Avanzar.
        2: Puede Avanzar. 
        */
        email: 0
      },
      timer: {
        email: null
      }
    };
  },
  computed: {
    /**
     * El numero puede tener espacios en blanco porque a veces
     * el numero ya esta registrado con espacios en blanco y si este es el caso,
     * al remover los espacio no hace match en la DB.
     */
    disabledNext() {
      if (this.phone.length > 2 && /^[0-9\s]+$/.test(this.phone)) return true;
      return false;
    },
    joinData() {
      return this.exBr.name + this.exBr.email + this.exBr.real_estate_name;
    },
    validEmail() {
      if (this.valid && this.exBr.email.length > 3) return true;
      return false;
    },
    email() {
      return this.exBr.email;
    }
  },
  watch: {
    //Veficar que los whatch esten funcionanando bien...
    email: function(e) {
      if (this.search.phone == 1) return 0;
      setTimeout(() => {
        this.verificationFinal();
        this.validateEmailForSearch(e);
      }, 100);
    },
    joinData: function() {
      this.verificationFinal();
    },
    validationScreen: function(e) {
      this.$emit("setCurrentScreen", e);
    }
  },
  methods: {
    //Search Email...
    validateEmailForSearch() {
      this.validSearchEmail = false;
      this.search.email = 0;
      this.loading.email = false;
      this.validSearchEmail = false;
      this.verificationFinal();
      //Dentro del change...
      clearTimeout(this.timer.email);
      this.validSearchEmail = false;
      this.search.email = 0;
      this.loading.email = false;
      if (!this.validEmail) return 0;
      //Si cumple la validación puede continuar..
      if (this.validEmail) {
        this.loading.email = true;
        this.timer.email = setTimeout(() => {
          this.searchEmail();
        }, 1000);
      }
    },
    searchEmail() {
      ///Busqueda de email
      const data = {
        email: this.exBr.email.replace(/ /g, "")
      };
      searchByRepeated(data).then(response => {
        this.loading.email = false;
        if (response.data.status == 1) {
          this.search.email = 1;
          this.validSearchEmail = false;
        }
        if (response.data.status == 0) {
          this.search.email = 2;
          this.validSearchEmail = true;
        }

        setTimeout(() => {
          this.verificationFinal();
        }, 100);
      });
    },
    // ----------------------------------------------------
    nextBtnSearch() {
      this.loading.next = true;
      const data = {
        phone: this.countrySelectedCode + this.phone
      };
      //Utlizar el try cach
      searchByRepeated(data).then(response => {
        if (!response && !response.data && !response.data.status) {
          this.loading.next = false;
          return 0;
        }
        //Falta el try
        this.assignPhone();
        this.loading.next = false;
        if (response.data.status == 0) this.validateCreateExternalBroker();
        if (response.data.status == 1) this.externalBrokerExists(response.data);
        setTimeout(() => {
          this.verificationFinal();
        }, 100);
      });
    },
    //Si no se encuentra el broker externo  ------> 0
    validateCreateExternalBroker() {
      setTimeout(() => this.$refs.form.validate(), 100);
      this.search.phone = 0;
      this.validationScreen = 1;
      this.disabled.form = false;
      //Datos asignados en el formulario..
      this.exBr.email = this.exBr.email;
      if (this.tempPhone !== this.exBr.countrySelectedCode + this.exBr.phone) {
        this.exBr.name = "";
        this.exBr.email = "";
        this.exBr.real_estate_name = "";
      }
    },
    //Si se encuentra el broker externo  ---------> 1
    externalBrokerExists(data) {
      this.search.phone = 1;
      this.validationScreen = 1;
      this.disabled.form = true;
      //Datos asignados en el formulario..
      this.exBr.name = data.name;
      this.exBr.email = data.email;
      this.exBr.real_estate_name = data.real_estate_name;
    },
    assignPhone() {
      this.exBr.phone = this.phone;
      this.exBr.countrySelectedCode = this.countrySelectedCode;
    },
    back() {
      this.tempPhone = this.exBr.countrySelectedCode + this.exBr.phone;
      this.validationScreen = 0;
      this.$emit("setValidate", false);
      this.$emit("setData", {});
    },
    //Validations
    validationFullform() {
      if (this.exBr.name.length == 0) return false;
      if (this.exBr.email.length == 0) return false;
      if (this.exBr.phone.length == 0) return false;
      if (this.exBr.countrySelectedCode.length == 0) return false;
      return true;
    },
    validationJoin() {
      if (this.validEmail && this.validationFullform()) return true;
      return false;
    },
    //Utils
    verificationFinal() {
      // en caso de que no pongan correo
      if (this.exBr.email == "") {
        if (this.search.phone == 0 && this.exBr.name != "") {
          this.$emit("setValidate", true);
          this.$emit("setData", this.exBr);
          return 0;
        }
      }
      if (!this.validSearchEmail && this.search.phone == 0) {
        this.$emit("setValidate", false);
        this.$emit("setData", {});
        return 0;
      }
      if (this.search.phone == 1 && this.typeComponent === "Lead") {
        this.$emit("setValidate", true);
        this.$emit("setData", this.exBr);
        return 0;
      }
      if (!this.validationJoin()) {
        this.$emit("setValidate", false);
        this.$emit("setData", {});
        return 0;
      }
      if (this.search.phone == 0) {
        this.$emit("setValidate", true);
        this.$emit("setData", this.exBr);
        return 0;
      }
      this.$emit("setValidate", false);
      this.$emit("setData", {});
    }
  }
};
</script>
